<template>
  <b-modal id="Modalmappermission" hide-footer title="Permission Mapping">
    <div class="row">
      <!-- {{ data_user }}
      {{ items }} -->
      <div class="col-12">
        <div class="input-group mb-3">
          <select class="form-control" v-model="permission_id">
            <option value="null">Select Permission</option>
            <option v-for="(e, i) in items" :key="i" :value="e.permission_id">
              <p v-if="permission_mpaapin(e.permission_name)">
                {{ e.permission_name }}
              </p>
            </option>
          </select>
        </div>
        <button class="btn bt-main w-100" @click="confirm()">
          MAPPING PERMISSION
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modalmappermission",
  props: ["data_user"],
  data() {
    return {
      permission_id: null,
      items: [],
    };
  },
  watch: {
    props_data_user: function(v) {
      this.getuserolebyid();
    },
  },
  computed: {
    props_data_user() {
      return this.data_user;
    },
  },
  mounted() {
    this.getuserolebyid();
    this.getpermissionnonepage();
  },
  methods: {
    permission_mpaapin(data) {
      let permission_name = data;

      let getters = this.$store.getters["loginActions/GET_USER"];
      let { role } = getters;

      let check_mapping = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "Mapping Role To User";
      });
      let check_it = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "IT";
      });

      if (String(permission_name).trim() == "IT") {
        if (check_mapping !== undefined && check_it !== undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        if (check_mapping !== undefined) {
          return true;
        } else {
          return false;
        }
      }
    },
    async getuserolebyid() {
      try {
        if (this.data_user.id !== undefined) {
          let data = { id: this.data_user.id };

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `user/getuserolebyid`,
            data,
            1
          );

          this.permission_id = String(getAPI.data[0].permission_id).trim();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getpermissionnonepage() {
      let data = {
        find: this.find,
        page_size: this.page_size,
        page_num: this.page_num,
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `adminit/getpermissionnonepage`,
        data,
        1
      );

      this.items = getAPI.data;

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    validate() {
      if (this.permission_id == "null") {
        return true;
      } else {
        return false;
      }
    },
    async confirm() {
      try {
        if (this.validate()) {
          throw `กรุณาระบุชื่อการอนุญาต / Please Enter Permission Name`;
        }
        let data = {
          permission_id: this.permission_id,
          id: this.data_user.id,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `user/mappingpermission`,
          data,
          1
        );

        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        this.redata();
        this.hide();
        this.$emit("getuserole");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.permission_id = null;
    },
    hide() {
      this.$bvModal.hide("Modalmappermission");
    },
  },
};
</script>

<style></style>
