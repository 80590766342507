<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Manage User</p>
          </div>
        </div>
        <hr class="m-0 p-0" />

        <div class="row m-0 p-3">
          <div class="col-12 col-xl-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <select class="custom-select" v-model="mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>

              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="getuserole()"
              />
              <div class="input-group-append">
                <button class="btn bt-main" @click="getuserole()">ค้นหา</button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              outlined
              hover
              show-empty
              responsive
              class="font-0-9s"
            >
              <template #cell(row)="row">
                {{ row.index + 1 + (page_num - 1) * page_size }}
              </template>
              <template #cell(usertype)="row">
                <span class="badge badge-main"> {{ row.item.usertype }}</span>
              </template>
              <template #cell(map)="row">
                <span
                  v-html="$svg_icon.mapping"
                  v-if="permission_mpaapin(row.item)"
                  type="button"
                  class="mr-2 text-black-50"
                  @click="mapping_permission(row.item)"
                ></span>
              </template>
              <template #cell(store)="row">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                  v-if="permission_store(row.item)"
                >
                  <button
                    disabled
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    {{
                      row.item.store === "" || row.item.store === null
                        ? "No Store"
                        : row.item.store
                    }}
                  </button>
                  <button
                    type="button"
                    v-html="$svg_icon.mapping"
                    class="btn btn-sm btn-secondary"
                    @click="mapping_store(row.item)"
                  ></button>
                </div>
                <!-- <span
                  v-html="$svg_icon.mapping"
                  v-if="permission_mpaapin(row.item)"
                  type="button"
                  class="mr-2 text-black-50"
                  @click="mapping_permission(row.item)"
                ></span> -->
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
                <select
                  class="custom-select"
                  v-model="page_size"
                  @change="changepage($event)"
                >
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  @change="changepagenum($event)"
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalmappermission
      :key="reModalmappermission"
      :data_user="data_user"
      @getuserole="getuserole"
    />
    <ModalmapperStore
      :key="reModalmapperStore"
      :data_user="data_user"
      @getuserole="getuserole"
    />
  </div>
</template>

<script>
import Modalmappermission from "@/components/User/ManageUser/Modalmappermission.vue";
import ModalmapperStore from "@/components/User/ManageUser/ModalmapperStore.vue";
export default {
  name: "ManageUser",
  components: { Modalmappermission, ModalmapperStore },
  data() {
    return {
      reModalmappermission: Date.now(),
      reModalmapperStore: Date.now() + 5,
      data_user: "",
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      totalRows: 1,
      mode: "user",
      select_mode: [
        { name: "First Name", key: "user" },
        { name: "Permission Name", key: "permission" },
        { name: "Email", key: "email" },
      ],
      fields: [
        { key: "row", label: "Row Number" },
        { key: "firstname", label: "Firstname" },
        { key: "lastname", label: "Lastname" },
        { key: "email", label: "Email" },
        { key: "usertype", label: "User Type" },
        { key: "permission_name", label: "Permission Name" },
        { key: "map", label: "Permission Maaping", class: "text-center" },
        { key: "store", label: "Store Maaping", class: "text-center" },
      ],
      items: [],
    };
  },
  watch: {
    page_num: function() {},
    find: function() {
      if (this.find == "") {
        this.getuserole();
      }
    },
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let { mode, find } = this.$route.query;

      if (mode !== undefined && find !== undefined) {
        this.find = find;
        this.mode = mode;
      }
      this.getuserole();
    },
    permission_mpaapin(data) {
      let { permission_name } = data;

      let getters = this.$store.getters["loginActions/GET_USER"];
      let { role } = getters;

      let check_mapping = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "Mapping Role To User";
      });
      let check_it = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "IT";
      });

      if (String(permission_name).trim() == "IT") {
        if (check_mapping !== undefined && check_it !== undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        if (check_mapping !== undefined) {
          return true;
        } else {
          return false;
        }
      }
    },
    permission_store(data) {
      let { store } = data;

      let getters = this.$store.getters["loginActions/GET_USER"];
      let { role } = getters;

      let check_mapping = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "MapStore";
      });
      let check_it = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "IT";
      });

      if (check_mapping !== undefined) {
        return true;
      } else {
        return false;
      }
    },
    async mapping_role(data) {
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async remove_role(data) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบ ${data.role_name} / You need to delete ${data.role_name}?`
        );

        if (confirm) {
          let data_body = {
            role_id: data.role_id,
          };

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `adminit/removerole`,
            data_body,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.getuserole();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    async getuserole() {
      let data = {
        find: this.find,
        page_size: this.page_size,
        page_num: this.page_num,
        mode: this.mode,
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `user/getuserole`,
        data,
        1
      );

      this.items = getAPI.data;
      this.totalRows = getAPI.data[0].totalRow;

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    mapping_permission(data) {
      this.reModalmappermission = Date.now();
      this.data_user = data;
      setTimeout(() => {
        this.$bvModal.show("Modalmappermission");
      }, 100);
    },
    mapping_store(data) {
      this.reModalmapperStore = Date.now();
      this.data_user = data;
      setTimeout(() => {
        this.$bvModal.show("ModalmapperStore");
      }, 100);
    },
    changepage(e) {
      this.page_size = e.target.value;
      this.page_num = 1;
      this.getuserole();
    },
    changepagenum(e) {
      this.page_num = e;
      this.getuserole();
    },
  },
};
</script>

<style></style>
