<template>
  <b-modal id="ModalmapperStore" hide-footer title="Permission Store">
    <div class="row">
      <!-- {{ data_user }} -->
      <p class="font-600 px-3">
        {{ data_user.firstname }} {{ data_user.lastname }}
      </p>
      <!-- {{ items }} -->
      <div class="col-12">
        <div class="input-group mb-3">
          <multiselect
            placeholder="เลือกสาขา"
            :disabled="items.length === 0"
            :key="store"
            v-model="store"
            :options="items"
            :clear-on-select="true"
            :allow-empty="true"
            deselect-label=""
            select-label=""
            selected-label=""
            :custom-label="labelWithCity"
            label="STCODE"
            track-by="STCODE"
          >
            <div
              class="option__desc isDisabled"
              slot="option"
              slot-scope="props"
            >
              <span
                >{{ props.option.STORE + " - " + props.option.STCODE }}
              </span>
            </div>
          </multiselect>
        </div>
        <button class="btn bt-main w-100" @click="confirm()">
          MAPPING STORE
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getstore } from "@/api/ProductInfo";
export default {
  name: "ModalmapperStore",
  props: ["data_user"],
  data() {
    return {
      store: null,
      items: [],
    };
  },
  watch: {
    props_data_user: function(v) {},
  },
  computed: {
    props_data_user() {
      return this.data_user;
    },
  },
  mounted() {
    this.get_getstore();

    // this.getpermissionnonepage();
  },
  methods: {
    setStore() {
      let { store } = this.data_user;

      let index = this.items.findIndex(
        (e) => String(e.STCODE).trim() === String(store).trim()
      );

      if (index !== -1) {
        this.store = this.items[index];
      }
    },
    labelWithCity(val) {
      return `${val.STORE} - ${val.STCODE}`;
    },
    async get_getstore() {
      let getData = await getstore();
      // console.log(getData);
      if (getData.data.length > 0) {
        this.items = [{ STORE: "All", STCODE: "All" }, ...getData.data];

        this.setStore();
      }

      try {
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    permission_mpaapin(data) {
      let permission_name = data;

      let getters = this.$store.getters["loginActions/GET_USER"];
      let { role } = getters;

      let check_mapping = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "Mapping Role To User";
      });
      let check_it = Object.values(role).find((obj) => {
        return String(obj.role_name).trim() == "IT";
      });

      if (String(permission_name).trim() == "IT") {
        if (check_mapping !== undefined && check_it !== undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        if (check_mapping !== undefined) {
          return true;
        } else {
          return false;
        }
      }
    },

    // async getpermissionnonepage() {
    //   let data = {
    //     find: this.find,
    //     page_size: this.page_size,
    //     page_num: this.page_num,
    //   };
    //   let getAPI = await this.$serviceAPI.call_API(
    //     "post",
    //     `adminit/getpermissionnonepage`,
    //     data,
    //     1
    //   );

    //   this.items = getAPI.data;

    //   try {
    //   } catch (error) {
    //     this.$serviceMain.showErrorAlert(
    //       this,
    //       error.message == undefined ? error : error.message
    //     );
    //   }
    // },
    validate() {
      if (!!!this.store) {
        return true;
      } else {
        return false;
      }
    },
    async confirm() {
      try {
        if (this.validate()) {
          throw `กรุณาระบุชื่อสาขา/ Please Enter Stroe`;
        }
        let data = {
          store: this.store.STCODE,
          userId: this.data_user.id,
          updateBy: this.$store.getters["loginActions/GET_USER"].id,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `user/mappingstore`,
          data,
          1
        );

        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        this.redata();
        this.hide();
        this.$emit("getuserole");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.store = null;
    },
    hide() {
      this.$bvModal.hide("ModalmapperStore");
    },
  },
};
</script>

<style></style>
